/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Informace"}>
        <SiteHeader />

        <Column className="pb--60 pt--80" name={"information"} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} animS={null} style={{"maxWidth":1080}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left w--300 pt--50" content={"Wie hat es angefangen?"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--10" name={"information-2"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"Überschrift"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen. Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-62lsa9 --center bg--top pb--80 pt--60" name={"partei"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center pb--40 pt--40">
              
              <Title className="title-box" content={"<span style='color: white'>Wie freuen uns auf Sie!</span>"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"<span style='color: white'><a href=\"https://saywebpage.com\">Webproduktion stránek</a></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}